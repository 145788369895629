
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&family=Lato&family=Poppins:wght@300;400&family=Roboto+Slab:wght@400;700&family=Work+Sans:ital,wght@1,500&family=Ysabeau+Office:ital,wght@0,1;0,100;0,200;1,200;1,400;1,500&display=swap');

.login{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    border-radius: 5%;
}
body {
  margin: 0;
  padding: 0;
  background-color: #EFEFEF;
  
}

.forms input,
.forms textarea {
  background-color: white;
  color: black;
  border-radius: 8px; 
}

.formmain{
    background-color:#95c1a3;
    height: 100%;
    width: 100%;
    max-width: 600px; 
    padding: 30px;
    margin-bottom:5%;   
    border-radius: 8px; 
}


.formspan{
  color: red;
}

@media (max-width: 768px) {
    .formmain {
      width: 80%; 
    }
    .contactus {
      margin-top: 30%;
    }
    .formsubheading{
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .formmain {
      width: 90%; 
    }
  }