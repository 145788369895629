@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
.aboutus-page-main{
    margin:5%;
    justify-content: center;
    align-items: center;
    text-align: justify;
    font-family: "Libre Franklin", sans-serif;
    /* background-size: cover;
    background-repeat: no-repeat;
    background-position: center; */
    /* background-color: rgb(0, 139, 139);    */
}

.aboutus-page-heading{
    
    margin-top: 5px;
    font-weight: 900;
    color: #F34414;
   
}
.abt-firsthead{
    width: 100.5vw;
    height: 70vh;
    /* background-color: rgba(170, 243, 243, 0.7); */
    
}

.abtus-grid1 {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 10px;
  animation: fadeIn 2s ease-in-out;
  /* color: #ffff; */
}

.abtus-grid1-contnet {
  border-radius: 10px;
  padding: 20px;
  /* background-color: #5A233B; */
  background-color: #BDBBB5;
  margin-bottom: 20px;
  border: 2px solid #2F2F3B;
  
}

.animate__fadeIn {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* abtus-grid-2-with-image */
.abtus-grid-2-with-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 250px;
}

.abtus-grid-2-image {
  width: 80%;
  height: 50%;
  border-radius: 5%;
  animation: fadeInRight 2s infinite;
  margin-left: 8%;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

  @media screen and (min-width: 767px) {
    .abtus-grid1 {
      display: flex;
      gap: 2%;
    }
  }
  
  /* productandservices */
  .productandservices{
    display: flex;
    gap: 2%;
    height: 250px;
  }

  /* onlyservices */
  .onlyservices{
    display: flex;
    gap: 2%;
    
  }

  .animate__bounce {
    animation: bounce 3s infinite;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    60% {
      transform:translateY(-10px) ;
    }
  }

  @media screen and (max-width: 600px) {
    .hide-on-mobile .b {
      display: none;
    }
  }